import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Predicto.module.scss"
// import Laptop from "../../images/invocom/Laptop.webp"

function Conclusion() {
  return (
    <Container>
      <div className={styles.laptopImg}>
        <img
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557276_8acd588e62.webp"
          }
          alt="laptop"
          decoding="async"
          loading="lazy"
        />
      
      </div>
    </Container>
  )
}

export default Conclusion
