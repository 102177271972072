import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/predicto/Banner"
import Technologiesweuse from "../../components/invocom/Technologiesweuse"
import Goals from "../../components/predicto/Goals"
import Result from "../../components/predicto/Result"
import Achievements from "../../components/predicto/Achievements"
import CenterImage from "../../components/predicto/CenterImage"
import Forecasting from "../../components/predicto/Forecasting"
import Predicto from "../../components/predicto/Predicto"
import AIConsultingService from "../../components/invocom/Introsection"
import KeyFeatures from "../../components/invocom/KeyFeatures"
import SEORevamp from "../../components/common/SEO_Revamp"
import Conclusion from "../../components/predicto/Conclusion"
import Workflowpredicto from "../../components/invocom/WorkflowPredicto"
import KeyChallengesSolution from "../../components/invocom/KeyChalanges"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const predicto = ({ data }) => {
  // console.log(data)

  const heroSection = data?.strapiPage?.sections[0]
  const consultingService = data?.strapiPage?.sections[1]
  const goals = data?.strapiPage?.sections[3]
  const work = data?.strapiPage?.sections[4]
  const key = data?.strapiPage?.sections[5]
  const achievements = data?.strapiPage?.sections[6]
  const result = data?.strapiPage?.sections[7]
  const engagementModel = data?.strapiPage?.sections[2]
  const conclusion = data?.strapiPage?.sections[8]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        predicto={true}
        showImage={false}
      />
      <Technologiesweuse hideCurve={true} strapiData={heroSection} />
      <AIConsultingService strapiData={consultingService} predicto={true} />
      <KeyFeatures strapiData={engagementModel} predicto={true} />
      <Goals strapiData={goals} />
      <Workflowpredicto strapiData={work} predicto={true} />
      <Forecasting strapiData={work} />
      <KeyChallengesSolution strapiData={key} />
      <Predicto />
      <Achievements strapiData={achievements} />
      <Result strapiData={result} />
      <CenterImage />
      <Conclusion strapiData={conclusion} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query predicto {
    strapiPage(slug: { eq: "predicto" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData {
            caseServices {
              invocom_caseStudy {
                skills
              }
              caseStudy
            }
          }
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default predicto
