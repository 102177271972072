import React from "react"
import * as styles from "./Achievements.module.scss"
import { Container, Row, Col } from "react-bootstrap"

function Enhancements({ strapiData }) {
  return (
    <div className={styles.enhancements}>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }}></h2>
        <Row className="gap-30">
          {strapiData?.cards?.map((e, i) => (
            <Col lg={4} md={6} xs={12}>
              <div className={styles.cards}>
                <img src={e?.image1[0]?.localFile?.publicURL} alt="tick" />
                <div>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: e?.title,
                    }}
                  ></h3>
                  <p
                    className={styles.mainDes}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
