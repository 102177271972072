import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Conclusion.module.scss"

function Conclusion({ strapiData }) {
  return (
    <Container>
      <div className={styles.conclusionDiv}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h2
              className={styles.conclusionHeading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <p
              className={styles.conclusionText}
              dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
            />
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Conclusion
