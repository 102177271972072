import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./CenterImage.module.scss"
// import Laptop from "../../images/invocom/Laptop.webp"

function Conclusion() {
  return (
    <Container>
      <div className={styles.laptopImg}>
        <img
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557275_c3396f13c8.webp"
          }
          alt="laptop"
          decoding="async"
          loading="lazy"
        />
        <div>
          <img
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557153_5d1c8ed528.webp"
            }
            alt="laptop"
            decoding="async"
            loading="lazy"
          />
          <img
          className="mt-5"
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557151_08e5e9cea6.webp"
            }
            alt="laptop"
            decoding="async"
          />{" "}
        </div>
      </div>
    </Container>
  )
}

export default Conclusion
