import React, { useState } from "react"
import * as styles from "./Forecasting.module.scss"
import { Container, Row, Col } from "react-bootstrap"

const data = [
  {
    head: "Stock Trend Forecasting",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2024_12_18_at_10_35_03_PM_1_6892b6c345.webp",
    skills: [
      {
        subHead: "Data Ingestion",
        subSkill: [
          { skill: "Collect real-time stock data from multiple sources." },
          { skill: "Clean and preprocess the data for consistency." },
        ],
      },
      {
        subHead: "Model Training and Prediction:",
        subSkill: [
          { skill: "Deep learning models analyze historical and live data." },
          { skill: "Predict short-term stock trends with high accuracy." },
        ],
      },
      {
        subHead: "Insight Delivery:",
        subSkill: [
          {
            skill:
              "Results are visualized in dashboards and delivered as daily briefings.",
          },
        ],
      },
    ],
  },
  {
    head: "Automated Trading",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2024_12_18_at_10_55_19_PM_1_b5b4ae8adc.webp",
    skills: [
      {
        subHead: "Query Submission:",
        subSkill: [
          { skill: "Users submit questions regarding website functionality." },
        ],
      },
      {
        subHead: "Response Generation:",
        subSkill: [
          {
            skill:
              "The chatbot provides real-time assistance using predefined FAQs and NLP techniques.",
          },
        ],
      },
      {
        subHead: "Escalation:",
        subSkill: [
          {
            skill:
              "Complex queries are forwarded to human support agents for resolution.",
          },
        ],
      },
    ],
  },
  {
    head: "Chatbot for Investment",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/rb_5403_1_15cdf034cf.webp",
    skills: [
      {
        subHead: "User Interaction:",
        subSkill: [{ skill: "Users query the chatbot for investment advice." }],
      },
      {
        subHead: "Data Analysis:",
        subSkill: [
          {
            skill:
              "The chatbot processes real-time contest data to identify optimal investment opportunities.",
          },
        ],
      },
      {
        subHead: "Recommendation Delivery:",
        subSkill: [
          {
            skill: "AI delivers actionable suggestions based on the analysis.",
          },
        ],
      },
    ],
  },
  {
    head: "Support Chatbot",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2024_12_18_at_10_46_17_PM_1_1_8e18b388ad.webp",
    skills: [
      {
        subHead: "Signal Generation:",
        subSkill: [
          {
            skill:
              "Models identify trading opportunities based on trend forecasts.",
          },
        ],
      },
      {
        subHead: "Trade Execution:",
        subSkill: [
          {
            skill: "Alpaca API automates the execution of trades in real-time.",
          },
        ],
      },
      {
        subHead: "Performance Monitoring:",
        subSkill: [
          {
            skill: "Dashboards track trading performance, ROI, and efficiency.",
          },
        ],
      },
    ],
  },
]

function Forecasting({ strapiData }) {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div className={styles.forecasting}>
      <Container>
        <div className={styles.heading}>
          {data?.map((e, i) => (
            <h3
              key={i}
              onClick={() => setActiveIndex(i)}
              style={{
                backgroundColor:
                  activeIndex === i
                    ? "rgba(34, 118, 255, 1)"
                    : "rgba(233, 233, 233, 1)",
                color: activeIndex === i ? "white" : "inherit",
              }}
            >
              {e?.head}
            </h3>
          ))}
        </div>
        <Row className="align-items-center">
          <Col lg={6}>
            {data[activeIndex]?.skills?.map((item, i) => (
              <div className={styles.data} key={i}>
                <span>0{i + 1}</span>
                <div>
                  <p className={styles.subHead}>{item?.subHead}</p>
                  {item?.subSkill?.map((subItem, j) => (
                    <p className={styles.skill} key={j}>
                      {subItem?.skill}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </Col>
          <Col lg={6} className="text-center">
            <img src={data[activeIndex]?.img} alt={data[activeIndex]?.head} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Forecasting
