import React from "react"
import * as styles from "./Goals.module.scss"
import { Container, Row, Col } from "react-bootstrap"

function Enhancements({ strapiData }) {
  return (
    <div className={styles.enhancements}>
      <Container>
        <Row>
          <Col Col lg={4}>
            <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }}></h2>
          </Col>
          <Col lg={8} className="">
            <Row className="gap-30">
              {strapiData?.cards?.map((e, i) => (
                <Col lg={6} className="d-flex">
                  <div className={styles.cards}>
                    <span>0{i + 1}</span>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.title,
                      }}
                    ></p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
